import React from "react";
import { Helmet } from "react-helmet"
import { Link } from "gatsby";

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from "../components/Layout";

const Service = ({ pageContext,  }) => {
    const { item, categoryTitle } = pageContext;
    const lowercaseCategoryTitle = categoryTitle.toLowerCase();

    const articleBody = JSON.parse(item.body.body);                        
    const articleBodyHtml = documentToReactComponents(articleBody, {});

    const seoTitle = item.seoTitle;
    const seoDescription = item.seoDescription;
    const seoKeywords = item.seoKeywords.join(", ");

    return (
        <Layout stickyHeader={false}>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                <meta name="keywords" content={seoKeywords} />
            </Helmet>
            <div class={`page-in-${lowercaseCategoryTitle}`}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 pull-left">
                            <div class="page-in-name">
                                {item.title}
                            </div>
                        </div>
                        <div class="col-lg-6 pull-right">
                            <div class="page-in-bread">
                                <Link to="/">
                                    <strong>Home</strong>
                                </Link>
                                {` / `} 
                                <Link to={`/${lowercaseCategoryTitle}`}>
                                    <strong>{categoryTitle}</strong>
                                </Link>
                                {` / `}
                                {item.title}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <br />                        
                        {articleBodyHtml}
                    </div>
                </div>

                <br />
                <br />
            </div>
        </Layout>
    );
}

export default Service;